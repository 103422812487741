<template>
    <ul id="vmenu" :class="{vactive: active}" :style="{top:top, left:left}">
        <li v-for="menu in vmenus" :key='menu.id'>
            <vmenu-item :menuitem="menu"></vmenu-item>
        </li>
    </ul>
</template>
<script>
import VmenuItem from './VmenuItem.vue';
import MenuMouseEvent from '../menuevents';
export default {    
    computed: {
        vmenus(){
            const menuMouseEvent = this.$store.state.menuMouseEvent;
            const mouseOverHmentItem = this.$store.state.mouseOverHmenuItem;
            const selectedHmenuItem = this.$store.state.selectedHmenuItem;
            let vmenus = null;
            if (menuMouseEvent === MenuMouseEvent.MENU_EVENT_TYPE_MOUSEOVER){
                vmenus = mouseOverHmentItem.menuitem.vmenus;   
            }else if (   menuMouseEvent === MenuMouseEvent.MENU_EVENT_TYPE_CLICK 
                      || menuMouseEvent === MenuMouseEvent.MENU_EVENT_TYPE_MOUSEOUT){
                if (selectedHmenuItem !== null){
                    vmenus = selectedHmenuItem.menuitem.vmenus;  
                }else{
                    vmenus = []
                }
            }
            return vmenus;
        },
        active(){
            return this.$store.state.vmenuActive;
        },
        top(){
            return `${this.$store.state.vmenuTop}px`;
        },
        left(){
            return `${this.$store.state.vmenuLeft}px`;
        }
    },
    components:{
        VmenuItem
    }
}
</script>

<style scoped>
    /* @media only screen and (max-width: 767px){
        ul#vmenu{
            display: none;
            list-style-type: none;
            margin: 0;
            padding: 0;
            background-color: #E0115F; 
            left: 1px; 
            max-width: 50%;
        }

        ul#vmenu > li{               
            display: block;
        }

        ul#vmenu > li:nth-child(1){        
            margin-top: 2px;            
            display: block;
        }

        ul#vmenu > li > a{
            background: #E0115F;
            font-weight: bold;
            color: #fff; 
            cursor: pointer;                
            padding: 5px;
            text-align: left;
            text-shadow: 1px 1px 1px #000;
            border-radius: 3px;
            text-decoration: none;    
            font-size: var(--mobile-menu-font-size);
            display: block;
        }

        ul#vmenu > li > a:hover{
            background: #2196F3;
            color: white;
        } 
    } */

         ul#vmenu{
            display: none;
            list-style-type: none;
            margin: 0;
            padding: 0;
            background-color: var(--background-color-one); 
            left: 1px; 
            max-width: 50%;
            border: 1px solid #848484;
        }

        ul#vmenu > li{               
            display: block;
        }

        ul#vmenu > li:nth-child(1){        
            margin-top: 2px;            
            display: block;
        }

        ul#vmenu > li > a{
            /* background: #E0115F; */
            font-weight: bold;
            color: #fff; 
            cursor: pointer;                
            padding: 5px;
            text-align: left;
            text-shadow: 1px 1px 1px #000;
            /* border-radius: 3px; */
            text-decoration: none;    
            font-size: var(--mobile-menu-font-size);
            display: block;
        }

        ul#vmenu > li > a:hover{
            /* background: #2196F3; */
            color: var(--menu-hover-color)
        } 
  

    .vactive{
            position: absolute;
            display: block !important;    
            max-width: 50%;
            /*height: 100%;    */
            overflow: auto;
            background-color: rgba(0, 153, 102, 10);
        }
</style>
